import { useAuth } from "~/hooks/useAuth";

export default function LoginStatus() {
  const { user, isAuthenticated, loading, signOut } = useAuth();

  return (
    <div className="fixed top-4 right-4 bg-white dark:bg-zinc-700 p-2 rounded-lg shadow-md z-50">
      {loading ? (
        <div className="flex items-center space-x-2">
          <div className="animate-pulse w-4 h-4 rounded-full bg-gray-300 dark:bg-gray-500"></div>
          <span className="text-sm">Loading...</span>
        </div>
      ) : isAuthenticated ? (
        <div className="flex items-center space-x-3">
          {user?.image && (
            <img 
              src={user.image} 
              alt="Profile" 
              className="w-8 h-8 rounded-full" 
            />
          )}
          <div className="flex flex-col">
            {user?.name && (
              <span className="text-sm font-medium">
                {user.name}
              </span>
            )}
            {user?.email && (
              <span className="text-xs text-gray-600 dark:text-gray-400">
                {user.email}
              </span>
            )}
            {!user?.name && !user?.email && (
              <span className="text-sm font-medium">
                Logged in
              </span>
            )}
            <div className="flex items-center space-x-2 mt-1">
              <button 
                onClick={() => signOut()}
                className="text-xs text-blue-600 dark:text-blue-400 hover:underline text-left"
              >
                Log out
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex items-center space-x-2">
          <span className="text-sm text-red-500">Not logged in</span>
          <a href="/login" className="text-xs text-blue-600 dark:text-blue-400 hover:underline">
            Login
          </a>
        </div>
      )}
    </div>
  );
} 