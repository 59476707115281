import { useAuth } from "~/hooks/useAuth"
import { Navigate } from "@remix-run/react"
import type { ReactNode } from "react"

interface AuthGuardProps {
  children: ReactNode
  redirectTo?: string
}

export default function AuthGuard({ 
  children, 
  redirectTo = "/login" 
}: AuthGuardProps) {
  const { isAuthenticated, loading } = useAuth()

  if (loading) {
    // Show loading state while checking authentication
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    )
  }

  // If not authenticated, redirect to login
  if (!isAuthenticated) {
    return <Navigate to={redirectTo} />
  }

  // If authenticated, show the protected content
  return <>{children}</>
} 