import {
  json,
  redirect,
  type LinksFunction,
  type LoaderFunctionArgs,
  type MetaFunction } from
'@remix-run/cloudflare';
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useLocation,
  isRouteErrorResponse,
  useRouteError } from
'@remix-run/react';
import { parse } from 'cookie';
import type { FC, ReactNode } from 'react';
import { useRef } from 'react';
import { useFullscreen, useToggle } from 'react-use';

import { QueryClient, QueryClientProvider } from 'react-query';
import tailwind from '~/styles/tailwind.css';
import { elementNotContainedByClickTarget } from './utils/elementNotContainedByClickTarget';
import getUsername from './utils/getUsername.server';
import { cn } from './utils/style';
import { AuthProvider } from './hooks/useAuth';
import AuthGuard from './components/AuthGuard';
import LoginStatus from './components/LoginStatus';

// Define paths that require authentication
const PROTECTED_PATHS = [
'/new' // Room creation path that requires authentication
// Add other protected paths here
];

function addOneDay(date: Date): Date {
  const result = new Date(date);
  result.setTime(result.getTime() + 24 * 60 * 60 * 1000);
  return result;
}

export const loader = async ({ request, context }: LoaderFunctionArgs) => {
  const url = new URL(request.url);

  // We still need username validation, but won't restrict access for most paths
  const username = await getUsername(request);

  // Only protect specific paths (like room creation)
  const isProtectedPath = PROTECTED_PATHS.some((path) => url.pathname.startsWith(path));

  if (isProtectedPath && !username) {
    const redirectUrl = new URL(url);
    redirectUrl.pathname = '/set-username';
    redirectUrl.searchParams.set('return-url', request.url);
    throw redirect(redirectUrl.toString());
  }

  const defaultResponse = json({
    userDirectoryUrl: context.env.USER_DIRECTORY_URL
  });

  // we only care about verifying token freshness if request was a user
  // initiated document request.
  // https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Sec-Fetch-User
  const secFetchUser = request.headers.get('Sec-Fetch-User');
  if (secFetchUser !== '?1') return defaultResponse;
  const cookiesHeader = request.headers.get('Cookie');
  if (!cookiesHeader) return defaultResponse;
  const { CF_Authorization } = parse(cookiesHeader);
  if (!CF_Authorization) return defaultResponse;

  const [, payload] = CF_Authorization.split('.');
  const data = JSON.parse(atob(payload));
  const expires = new Date(data.exp * 1000);
  const now = new Date();
  if (addOneDay(now) > expires) {
    const headers = new Headers();
    ['CF_Authorization', 'CF_AppSession'].forEach((cookieName) =>
    headers.append(
      'Set-Cookie',
      `${cookieName}=; Expires=${new Date(0).toUTCString()}; Path=/;`
    )
    );

    throw redirect(request.url, { headers });
  }

  return defaultResponse;
};

export const meta: MetaFunction = () => [
{
  title: 'Remote Therapy'
}];


export const links: LinksFunction = () => [
{ rel: 'stylesheet', href: tailwind },
{
  rel: 'apple-touch-icon',
  sizes: '180x180',
  href: '/apple-touch-icon.png?v=orange-emoji'
},
{
  rel: 'icon',
  type: 'image/png',
  sizes: '32x32',
  href: '/favicon-32x32.png?v=orange-emoji'
},
{
  rel: 'icon',
  type: 'image/png',
  sizes: '16x16',
  href: '/favicon-16x16.png?v=orange-emoji'
},
{
  rel: 'manifest',
  href: '/site.webmanifest',
  crossOrigin: 'use-credentials'
},
{
  rel: 'mask-icon',
  href: '/safari-pinned-tab.svg?v=orange-emoji',
  color: '#faa339'
},
{
  rel: 'shortcut icon',
  href: '/favicon.ico?v=orange'
}];


const Document: FC<{children?: ReactNode;}> = ({ children }) => {
  const fullscreenRef = useRef<HTMLBodyElement>(null);
  const [fullscreenEnabled, toggleFullscreen] = useToggle(false);
  useFullscreen(fullscreenRef, fullscreenEnabled, {
    onClose: () => toggleFullscreen(false)
  });
  return (
    // some extensions add data attributes to the html
    // element that React complains about.
    <html className="h-full" lang="en" suppressHydrationWarning>
			<head>
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<meta name="apple-mobile-web-app-title" content="Orange Meets" />
				<meta name="application-name" content="Orange Meets" />
				<meta name="msapplication-TileColor" content="#ffffff" />
				<meta
          name="theme-color"
          content="#ffffff"
          media="(prefers-color-scheme: light)" />

				<meta
          name="theme-color"
          content="#232325"
          media="(prefers-color-scheme: dark)" />

				<Meta />
				<Links />
			</head>
			<body
        className={cn(
          'h-full',
          'bg-white',
          'text-zinc-800',
          'dark:bg-zinc-800',
          'dark:text-zinc-200'
        )}
        ref={fullscreenRef}
        onDoubleClick={(e) => {
          if (
          e.target instanceof HTMLElement &&
          !elementNotContainedByClickTarget(e.target))

          toggleFullscreen();
        }}>

				{children}
				<ScrollRestoration />
				<div className="hidden" suppressHydrationWarning>
					{/* Replaced in entry.server.ts */}
					__CLIENT_ENV__
				</div>
				<Scripts />
				<LiveReload />
			</body>
		</html>);

};

export function ErrorBoundary() {
  const error = useRouteError();

  // Handle generic errors
  let errorMessage = "An unexpected error occurred!";
  let statusCode = 500;

  // Handle route errors (redirects, etc.)
  if (isRouteErrorResponse(error)) {
    errorMessage = error.data || "Error loading this page";
    statusCode = error.status;
  } else if (error instanceof Error) {
    errorMessage = error.message;
  }

  // On iOS, we sometimes get errors related to session handling
  const isAuthError = errorMessage.toLowerCase().includes('auth') ||
  errorMessage.toLowerCase().includes('session') ||
  errorMessage.toLowerCase().includes('permission');

  return (
    <html lang="en">
			<head>
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<Meta />
				<Links />
			</head>
			<body className="bg-white dark:bg-zinc-900 text-black dark:text-white min-h-screen">
				<div className="flex flex-col items-center justify-center min-h-screen p-4">
					<div className="w-full max-w-md p-6 bg-white rounded-lg shadow-md dark:bg-zinc-800">
						<h1 className="text-2xl font-bold mb-4 text-center">
							{statusCode === 404 ? 'Page Not Found' : 'Something went wrong'}
						</h1>
						
						<div className="mb-6 p-3 bg-red-100 text-red-800 rounded dark:bg-red-800 dark:text-red-100">
							{errorMessage}
						</div>
						
						{isAuthError &&
            <div className="mb-4 p-3 bg-yellow-100 text-yellow-800 rounded dark:bg-yellow-800 dark:text-yellow-100">
								<p>Это может быть связано с вашим статусом аутентификации.</p>
								<p className="mt-2">Попробуйте следующее:</p>
								<ul className="list-disc ml-5 mt-2">
									<li>Убедитесь, что вы вошли в систему</li>
									<li>Проверьте, есть ли у вас активный промо-код</li>
									<li>Попробуйте обновить страницу</li>
									<li>Очистите кэш и куки браузера</li>
								</ul>
							</div>}

						
						<div className="flex flex-col space-y-2">
							<a
                href="/"
                className="w-full text-center bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded">

								Вернуться на главную
							</a>
							
							<a
                href="/login"
                className="w-full text-center bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded">

								Войти
							</a>
							
							<a
                href="/promocode"
                className="w-full text-center bg-purple-500 hover:bg-purple-600 text-white font-semibold py-2 px-4 rounded">

								Активировать промо-код
							</a>
						</div>
					</div>
				</div>
				<Scripts />
			</body>
		</html>);

}

const queryClient = new QueryClient();

// Component to handle protected routes
function ProtectedRoutes({ children }: {children: ReactNode;}) {
  const location = useLocation();

  // Check if the current path is a protected path
  const isProtectedPath = PROTECTED_PATHS.some((path) =>
  location.pathname.startsWith(path)
  );

  // If it's a protected path, wrap with AuthGuard
  if (isProtectedPath) {
    return <AuthGuard>{children}</AuthGuard>;
  }

  // Otherwise, don't restrict access
  return <>{children}</>;
}

export default function App() {
  const { userDirectoryUrl } = useLoaderData<typeof loader>();
  const location = useLocation();

  // Check if the current path is a room route to hide the login status component
  // This will match room routes in various formats:
  // 1. /roomname/room - direct room access
  // 2. /_room/ - the room layout route
  // 3. Any route that contains "room" as a segment after the first slash
  const isRoomRoute = location.pathname.startsWith('/_room') ||
  /^\/[^\/]+\/room(\/|$)/.test(location.pathname);

  return (
    <Document>
			<div id="root" className="h-full bg-inherit isolate">
				<QueryClientProvider client={queryClient}>
					<AuthProvider>
						{/* Only show LoginStatus when not in a room */}
						{!isRoomRoute && <LoginStatus />}
						<ProtectedRoutes>
							<Outlet
                context={{
                  userDirectoryUrl
                }} />

						</ProtectedRoutes>
					</AuthProvider>
				</QueryClientProvider>
			</div>
		</Document>);

}